.ant-input {
    background: #ffe5e9 !important;
    border-color: #ffffff !important;
}

.ant-menu-title-content {
    color: #ffffff !important;
    font-weight: bold;
}

.ant-menu-item-icon {
    color: #ffffff !important;
    font-weight: bold;
}

.ant-input::placeholder {
    color: #838383 !important;
}

.ant-table-container {
    background: #F6E7EA !important;
}

.ant-table-thead > tr > th {
    background: #F3F3F4 !important;
    color: #000000 !important;
    font-weight: bold !important;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.input-group-addon {
    padding: 0px 0px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    color: white;
    text-align: center;
    background-color: #ff0000;
    // border: 1px solid #ccc;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
}

input {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell;
    padding: 6px 12px;
    //border: 1px solid #ccc;
    margin: 0;
}

.suffix input {
    // border-radius: 4px 0px 0px 4px;
}
.suffix .input-group-addon {
    border-left: 0;
    // border-radius: 0px 4px 4px 0px;
}
.prefix input {
    // border-radius: 0px 4px 4px 0px;
}
.prefix .input-group-addon {
    border-right: 0;
    // border-radius: 4px 0px 0px 4px;
}
.presuffix input {
    // border-radius: 0px;
}
.input-group-addon.prefix {
    // border-radius: 4px 0px 0px 4px;
    border-right: 0;
}
.input-group-addon.suffix {
    // border-radius: 0px 4px 4px 0px;
    border-left: 0;
}
.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

