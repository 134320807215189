.admin-main-container {
  background-repeat: no-repeat;
  background-size: 100% 900px;
  background-image: url(../../Assets/admin-main-background.png);
  width: 100%;
  background-color: white
}

.ant-menu-root,
.ant-layout-sider-children,
.ant-menu {
  background: #ff0000 !important;
}

.ant-menu-item-only-child:focus {
  background-color: white !important;
}

.ant-menu-item-only-child:hover .ant-menu-title-content {
  color: #ff0000 !important;
}

.ant-menu-item-only-child:hover .ant-menu-title-content:hover {
  color: white !important;
}

.ant-menu-item-only-child:focus .ant-menu-title-content {
  color: #ff0000 !important;
}

//.ant-menu-submenu {
//    background: #ff0000 !important;
//}
//
//.ant-menu-submenu:hover {
//    background: #ff0000 !important;
//}
//
////.ant-menu-title-content {
////  color: #ff0000 !important;
////}
//
//.ant-menu-submenu-title:hover {
//  color: #ff0000 !important;
//}
//
//.ant-menu-submenu-title:hover .ant-menu-title-content {
//  color: white !important;
//}
//
//.ant-menu-submenu-title:hover .ant-menu-item-icon {
//  color: white !important;
//}
//
//.ant-menu-item-only-child:hover .ant-menu-title-content {
//  color: #ff0000 !important;
//}
//

//.ant-menu > .ant-menu-item:focus {
//  color: white !important;
//  background: white !important;
//}
//
//.ant-menu > .ant-menu-title-content:focus {
//  color: deeppink !important;
//}

//.ant-menu > .ant-menu-item:hover {
//  color: black !important;
//  background: white !important;
//}
//
//.ant-menu > .ant-menu-submenu:hover,
//.ant-menu> .ant-menu-submenu-active,
//.ant-menu > .ant-menu-item-open,
//.ant-menu> .ant-menu-submenu-open,
//.ant-menu > .ant-menu-item-selected,
//.ant-menu > .ant-menu-submenu-selected {
//  color: red;
//  background: white;
//}
